
import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom"
import axios from 'axios';
import PORTNUMBER from '../../api/api'
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment'






const ContactDetails = () => {
    const { id } = useParams()
    const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("authtoken")
    const [AllOrder, setAllOrder] = useState([])



    useEffect(() => {
        axios.get(`${PORT}/contact/getContactById/${id}`, { headers: { authtoken: Token } }).then((res, err) => {
            if(res.data.data){
            setAllOrder(res.data.data);
            }
        })
    }, [PORT,Token,id])



    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Contact Details</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/contactQuries" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ttable-order-box shadow">
                    <div className="tab-head">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="order-details contact">
                                    <ul>
                                        <li>From :</li>
                                        <li>{AllOrder.name}</li>
                                        <li>Email :</li>
                                        <li>{AllOrder.email}</li>
                                        <li>Date : </li>
                                        <li>{moment(AllOrder.created_at).format("DD/MM/YYYY")}</li>
                                        <li>Message :</li>
                                        <li>
                                        {AllOrder.message}
                                            {/* {AllOrder.buyer && AllOrder.buyer !== null ? AllOrder.buyer['name'] : 'N/A'} */}
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    )
}
export default ContactDetails;





