
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import PORTNUMBER from '../../api/api'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';





const Setting = () => {

    const Token = localStorage.getItem("authtoken")
    const PORT = PORTNUMBER.PORTNUMBER

    
    const [AllSettings, setSettings] = useState([])
    const [editVlue, setEditVlue] = useState(true)




    const { handleSubmit } = useForm();

   
    

    const editHandel=(e)=>{
        setEditVlue(e)

    }
    const LoadSettings = useCallback (() => {
        axios
        .get(`${PORT}/setting/getSettingDetails`, {
          headers: { authtoken: Token },
        })
        .then((res) => {
          if (res.data.data) {
            setSettings(res.data.data);
          }
        });
    },[PORT,Token])

    useEffect(()=>{
        LoadSettings()

    },[LoadSettings])

   
    const onSubmit = async (data) => {
        const fd = new FormData();
        const getUpdateInputs = document.getElementsByClassName('settingUpdateInput');

        for (let i = 0; i < getUpdateInputs.length; i++) {
            if (getUpdateInputs[i].type === "file") {
                const getFile = getUpdateInputs[i].files[0]
                // obj.key = ;
                // obj.content = ;
                fd.append(getUpdateInputs[i].id, getFile)
            }else{
                // obj.key = getUpdateInputs[i].id;
                // obj.content = getUpdateInputs[i].value;
                fd.append(getUpdateInputs[i].id, getUpdateInputs[i].value)
            }
        }
       
        

       
       await axios.post(`${PORT}/setting/editSetting`, fd, { headers: { authtoken: Token } })
            .then((res) => {
                LoadSettings();
                toast.success("Settings are updated successfully.");
                setEditVlue(true)
       
            })
    };
    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Settings</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        {
                            editVlue ?  <>
                            <div className="row">
                                {
                                    AllSettings.map((setting)=>(
                                        <div key={setting.key} className="col-lg-6">
                                        <div className="form-group ">
                                            <label>{setting.label}</label>
                                            <input type="text" value={setting.content}  className="form-control" disabled/>
                                         
                                        </div>
                                    </div>
                                    ))
                                }
    
                                   </div>
                                   <div className="col-lg-12">
                                        <div className="theme-btn btn-group-right text-right">
                                     
                                            <button type="button" onClick={()=> editHandel(false)} className="btn">Edit</button>
                                        </div>
                                    </div>
                            </>:
                             <form onSubmit={handleSubmit(onSubmit)}>
                             <div className="row">
                                 {
                                     AllSettings.map((settings)=>(
                                         <div key={settings.key} className="col-lg-6">
                                             <label>{settings.label}</label>
                                        {
                                            settings.key === 'LOGO_URL' ? 
                                                <div className="form-group icon-position">
                                               <input type="file"  id={settings.key} className="form-control settingUpdateInput"  />
                                               {/* <img src={settings.content} alt="cat" style={{"width":"40px", "height":"40px"}}/> */}
                                                </div>
                                             :<div className="form-group icon-position">
                                             <input type="text" disabled={false} defaultValue={settings.content} id={settings.key} className="form-control settingUpdateInput"  required />
                                                     </div> 
                                        }
                                      
                                         
                                     </div>
                                     ))
                                 }
     
                                    </div>
                                    <div className="col-lg-12">
                                         <div className="theme-btn btn-group-right text-right">
                                             <button type="submit" className="btn">Update</button>
                                             <button type="button"  onClick={()=> editHandel(true)} className="btn">cancel</button>

                                         </div>
                                     </div>
                             </form>
                        }
                       
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Setting;