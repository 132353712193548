import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import moment from "moment";


import { GiFlowerPot } from 'react-icons/gi';


// icons
import { FaSearch } from "react-icons/fa";

import PORTNUMBER from "../../api/api";

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const UserManagement = () => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const Token = localStorage.getItem("authtoken");



  const [users, setUser] = useState([]);
  const [Count, setCount] = useState();
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState();
  const [userPerPage] = useState(50);

  const [pageNo, setpageNo] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterForm, setFilterForm] = useState({
    name:"",
    email:"",
    code:"",
    deviceStatus:"",
    startDate:"",
    endDate:"",
  });

 
  const LoadUser = useCallback( async (pageNumber) => {
    await axios
      .post(
        `${PORT}/getAllDevices`,
        { currentPage: pageNumber },
        { headers: { authtoken: Token } }
      )
      .then((res) => {
        if (res.data.data) {
          setUser(res.data.data.results);
          setCount(res.data.data.result.totalUser);
        }
      });

    setLoading(true);
  },[PORT,Token]);

  const handleFilter = async (filterForm) => {
    filterForm['currentPage']= pageNo;
    await axios
      .post(`${PORT}/user/filterDevice`, filterForm, {
        headers: { authtoken: Token },
      })
      .then((res, err) => {
        setUser(res.data.data);
        setCount(res.data.result.totalUser);
    setLoading(true);

      });
  };

  const resetLeadFilterForm = () => {
    LoadUser();
    // setRowData(userData);
    // setRowsPerPage(10);
  };
  const handleReset = (e) => {
    setLoading(false);

    e.preventDefault();
    const data = {
      name:"",
      email:"",
      code:"",
      deviceStatus:"",
      startDate:"",
      endDate:"",
    };

    setFilterForm(data);
    resetLeadFilterForm(data);
  };

  const handleChanges = (key, value) => {
    const data = { ...filterForm, [key]: value };
    setFilterForm(data);
    // handleFilter(data);
  };

  const handleDataSearch = (e) => {
    setLoading(false);

    e.preventDefault();
    const data = { ...filterForm };
    handleFilter(data);
  };

  useEffect(() => {
    LoadUser();
  }, [LoadUser]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(Count / userPerPage); i++) {
    pageNumbers.push(i);
  }

  // Change page

  const paginate = async (pageNumber) => {
    setLoading(false);
    setCurrentPage(pageNumber);
    LoadUser(pageNumber);
    setpageNo(pageNumber);
  };

  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="main-head">
            <h4>Device List</h4>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <form>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={filterForm.name}
                      onChange={(e) => handleChanges("name", e.target.value)}
                      className="form-control"
                     
                      aria-describedby="emailHelp"
                      placeholder=" Device Name"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <input
                      type="email"
                      value={filterForm.email}
                      onChange={(e) => handleChanges("email", e.target.value)}
                      className="form-control"
                     
                      aria-describedby="emailHelp"
                      placeholder="Owner Email"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={filterForm.code}
                      onChange={(e) => handleChanges("code", e.target.value)}
                      className="form-control"
                     
                      aria-describedby="emailHelp"
                      placeholder="Code"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      value={filterForm.deviceStatus}
                      onChange={(e) => handleChanges("deviceStatus", e.target.value)}
                    >
                      <option defaultValue>All Status</option>
                      <option value="Connected">Connected</option>
                      <option value="Disconnected">Disconnected</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group before-con">
                    <DatePicker
                      selectsStart
                      placeholderText="Click to select a date"
                      className="form-control"
                      selected={Date.parse(filterForm.startDate)}
                      maxDate={new Date()}
                      dateFormat="d MMMM, yyyy"
                      onSelect={(e) =>
                        handleChanges(
                          "startDate",
                          moment(e).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group before-con after">
                    <DatePicker
                      selectsEnd
                      placeholderText="Click to select a date"
                      className="form-control"
                      selected={Date.parse(filterForm.endDate)}
                      maxDate={new Date()}
                      dateFormat="d MMMM, yyyy"
                      onSelect={(e) =>
                        handleChanges("endDate", moment(e).format("YYYY-MM-DD"))
                      }
                    />{" "}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-right">
                  <button
                      className="btn"
                      onClick={(e) => handleDataSearch(e)}
                    >
                      Search
                    </button>
                    <button className="btn" onClick={(e) => handleReset(e)}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="form-search">
          <form>
            <div className="form-group icon-input top-search">
              <input
                type="text"
                className="form-control"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                placeholder="Search"
              />
              <FaSearch />
            </div>
          </form>
        </div>
        <div className="table-order shadow">
          <table>
            <tbody>
              <tr>
                <th>S.No</th>
                <th>Device Name</th>
                <th>Device Code</th>
                <th>Owner Email</th>
                <th>Plant Name</th>
                <th>Added On</th>
                <th>Status </th>
                {/* <th>Action</th> */}
              </tr>

              { (
                users && users.length ? (
                  users
                    .filter((val) => {
                     
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.code?val.code
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()):''
                      ) {
                        return val;
                      } else if (
                        val.email? val.email
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()):''
                      ) {
                        return val;
                      } else if (
                        val.plantName?val.plantName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()):''
                      ) {
                        return val;
                      }else if (
                        moment(val.created_at).format("DD/MM/YYYY").includes(searchTerm)
                      ) {
                        return val;
                      }else{
                        return false
                      }
                    })
                    .map((user,index) => (
                      <tr key={user._id}>
                         <td >{(50*((currentPage === undefined || currentPage === 0)? 1-1 : currentPage-1))+(index+1)}</td>              
                        <td>{user.name}</td>
                        <td>{user.code}</td>
                        <td>{user.email}</td>
                        <td>{user.plantName?user.plantName:'-'}</td>
                        <td>{moment(user.created_at).format("DD/MM/YYYY")}</td>
                        <td className="">
                          <span
                            className={`badge bg-success ${
                              user.deviceStatus === "Disconnected"
                                ? "inactive"
                                : "active"
                            }`}
                          >
                            {user.deviceStatus}
                          </span>
                        </td>
                        {/* <td className="action-top">
                                     
                                      
                                       
                                     {
                                         user.deviceStatus == "Disconnected" ?  <span onClick={()=>activeHandleShow(user._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={()=>inActiveHandleShow(user._id)} className="icons-action"><FaBan /></span>
                                       
                                     }
                                  
                                      
                                    </td> */}
                      </tr>
                    ))
                ) : (
                  <tr ><td className="no-data">NO DEVICE FOUND</td></tr>
                )
              ) }

              {/* <Posts posts={currentPosts} loading={loading} /> */}
            </tbody>
          </table>
          {
            !loading ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiFlowerPot />
                  </div>
                </div>
              </div> : ""
          }
          {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
        </div>

        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <p onClick={() => paginate(number)} className="page-link">
                  {number}
                </p>
              </li>
            ))}
          </ul>
        </nav>

    
      </div>

    </div>
  );
};
export default UserManagement;
