import React from "react"
import { Accordion, Card } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import { FaRegUser } from 'react-icons/fa';




const UserManagement=()=>{
    return(
        <Card>
        <Accordion.Toggle as={Card.Header} >
          <ul>
            <li className="deactive"><NavLink to="/userManagement" ><span className="image-nav"><FaRegUser/></span><span className="img-active"><FaRegUser/></span>User Management <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
            </li>

          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <ul>
              <li className=""><NavLink to="/userManagement"><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>User Management <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
              </li>
             
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
}

export default UserManagement;