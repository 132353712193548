import React, { useState, useEffect, useCallback } from "react";
// import order from './components/order';
import axios from "axios";
import moment from "moment";

import { GiFlowerPot } from 'react-icons/gi';

// icons
import { FaSearch } from "react-icons/fa";
import { FaEye } from "react-icons/fa";


import PORTNUMBER from "../../api/api";

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

// react bootstrap
// import { Modal, Button } from "react-bootstrap";

const OrderList = () => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const Token = localStorage.getItem("authtoken");

  const [searchTerm, setSearchTerm] = useState("");



  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userPerPage] = useState(50);
  const [Count, setCount] = useState();
  const [filterForm, setFilterForm] = useState({
    name:"",
    email:"",
    startDate:"",
    endDate:"",
  });

  // const activeHandleClose = () => setActiveShow(false);
  // const activeHandleShow = (id, status) => {
  //   setActiveShow(true);
  //   setStatusId(id);
  //   setStatusDelivery(status);
  // };

  // const activeOrder = async () => {
  //   await axios
  //     .post(
  //       `${PORT}/order/changeStatus`,
  //       { orderId: statusId, status: statusDelivery },
  //       { headers: { authtoken: Token } }
  //     )
  //     .then((res, err) => {
  //       LoadOrder();
  //       toast.success(`You succesfully ${statusDelivery} status`);
  //       activeHandleClose();
  //     });
  // };

  const LoadOrder = useCallback( async (pageNumber) => {
    await axios
      .post(
        `${PORT}/contact/getAllContactList`,
        { currentPage: pageNumber },
        { headers: { authtoken: Token } }
      )
      .then((res) => {
        setOrder(res.data.data.results);
        setCount(res.data.data.result.totalUser);
      });
    setLoading(true);
  },[PORT,Token]);

  const handleFilter = async (filterForm) => {
    await axios
      .post(`${PORT}/user/filterContact`,filterForm, {
        headers: { authtoken: Token },
      })
      .then((res, err) => {
        setOrder(res.data.data);
        setLoading(true);
      });
  };

  const resetLeadFilterForm = () => {
    LoadOrder ();
    // setRowData(userData);
    // setRowsPerPage(10);
  };
  const handleReset = (e) => {
    setLoading(false);

    e.preventDefault();
    const data = {
      name: "",
      email: "",
      startDate: "",
      endDate: "",
    };

    setFilterForm(data);
    resetLeadFilterForm(data);
  };

  const handleChanges = (key, value) => {
    const data = { ...filterForm, [key]: value };
    setFilterForm(data);
    // handleFilter(data);
  };

  const handleDataSearch = (e) => {
    setLoading(false);

    e.preventDefault();
    const data = { ...filterForm };
    handleFilter(data);
  };

  useEffect(() => {
    LoadOrder();
  }, [LoadOrder]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(Count / userPerPage); i++) {
    pageNumbers.push(i);
  }

  // Change page

  const paginate = async (pageNumber) => {
    setLoading(false);
    setCurrentPage(pageNumber);
    LoadOrder(pageNumber);
  };
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="main-head">
            <h4>Contact Queries</h4>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <form>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={filterForm.name}
                      onChange={(e) => handleChanges("name", e.target.value)}
                      className="form-control"
                     
                      aria-describedby="emailHelp"
                      placeholder="Sender Name"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="email"
                      value={filterForm.email}
                      onChange={(e) => handleChanges("email", e.target.value)}
                      className="form-control"
                     
                      aria-describedby="emailHelp"
                      placeholder="Sender Email"
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group before-con">
                    <DatePicker
                      selectsStart
                      placeholderText="Click to select a date"
                      className="form-control"
                      selected={Date.parse(filterForm.startDate)}
                      maxDate={new Date()}
                      dateFormat="d MMMM, yyyy"
                      onSelect={(e) =>
                        handleChanges(
                          "startDate",
                          moment(e).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group before-con after">
                    <DatePicker
                      selectsEnd
                      placeholderText="Click to select a date"
                      className="form-control"
                      selected={Date.parse(filterForm.endDate)}
                      maxDate={new Date()}
                      dateFormat="d MMMM, yyyy"
                      onSelect={(e) =>
                        handleChanges("endDate", moment(e).format("YYYY-MM-DD"))
                      }
                    />{" "}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-right">
                  <button
                      className="btn"
                      onClick={(e) => handleDataSearch(e)}
                    >
                      Search
                    </button>
                    <button className="btn" onClick={(e) => handleReset(e)}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="form-search">
          <form>
            <div className="form-group icon-input top-search">
              <input
                type="text"
                className="form-control"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                placeholder="Search"
              />
              <FaSearch />
            </div>
          </form>
        </div>
        <div className="table-order shadow">
          <table>
            <tbody>
              <tr>
              <th>S.No</th>
                <th>Sender Name</th>
                <th>Sender Email</th>
                <th>Received On</th>
                <th>Action </th>
              </tr>

              {(
                order && order.length ? (
                  order
                    .filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.email
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        moment(val.created_at).format("DD/MM/YYYY").includes(searchTerm)
                      ) {
                        return val;
                      }else{
                        return false
                      }
                    })
                    .map((user,index) => (
                      <tr key={user._id}>
                        <td>{(50*((currentPage === undefined || currentPage === 0)? 1-1 : currentPage-1))+(index+1)}</td>
                        <td>{user.name ? user.name : "-"}</td>
                        <td>{user.email ? user.email : "-"}</td>
                        <td>{moment(user.created_at).format("DD/MM/YYYY")}</td>
                        <td className="action-top">
                          <Link
                            to={`/contactDetails/${user._id}`}
                            className="icons-action"
                          >
                            <FaEye />
                          </Link>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr ><td className="no-data">NO CONTACT FOUND</td></tr>
                )
              ) }
            </tbody>
          </table>
          {
            !loading ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiFlowerPot />
                  </div>
                </div>
              </div> : ""
          }
        </div>

        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <p onClick={() => paginate(number)} className="page-link">
                  {number}
                </p>
              </li>
            ))}
          </ul>
        </nav>
      </div>
     
    </div>
  );
};
export default OrderList;
