import '../App.scss';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../assets/scss/css/style.scss'
import React, {useEffect, useCallback} from 'react';
import { useSelector } from 'react-redux';

import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'

import Protected from  '../protectiveRote/protectiveRote'

// sidenav header
import Header from  '../containers/header/header'
import SideNav from '../containers/sidenav/sidenav'

// dashboard
import Dasboard from '../views/dashboard/dashboard'

// user management
import UserMangement from '../views/userManagement/managementList/userManagement'
import ViewPlant from '../views/userManagement/viewPlants/viewPlant'
import UserViewDevice from '../views/userManagement/userDeviceList/userDeviceList'

// device management
import DeviceMangement from '../views/deviceMangement/deviceList/deviceList'

// content management
import ContentManagement from '../views/contentMangement/contentList/contentList'
import EditContent from '../views/contentMangement/editContent/editContent'


// contact quries
import ContactQuries from '../views/contactMangement/contactQuries/contactQuries'
import ContactDetails from '../views/contactMangement/contactDetails/contactDetails'

// login crediantials
import Login from '../views/auth/login/login'
import ResetPassword from '../views/auth/resetPassword/resetPassword'
import ResetMail from '../views/auth/resetMail/resetMail'
import ResetPasswordLink from '../views/auth/resetPwLink/resetPasswordLink'

// category management
import CategoryMangement from '../views/categoryMangement/categoryList/categoryList'
import EditCategory from '../views/categoryMangement/editCategory/editCategory'
import AddCategory from '../views/categoryMangement/addCtegory/addCategory'

// promo code List
import Setting from '../views/settingManage/settingMange/settingManage'



// cms pages 

import PrivacyPolicy from '../views/cmsPages/privacyPolicy/privacyPolicy'
import HOW_IT_WORK from '../views/cmsPages/howItWork/howItWork'
import FAQ from '../views/cmsPages/faq/faq'

// toaster 


import { ToastContainer, toast } from "react-toastify";
import ViewBlog from '../views/blogView/viewBlog/viewBlog'
import axios from 'axios';
import PORTNUMBER from '../views/api/api'


       




function App() {
  
  const Token = localStorage.getItem("authtoken");
  const PORT = PORTNUMBER.PORTNUMBER


  window.onbeforeunload = function (e) {

    window.localStorage.unloadTime = JSON.stringify(new Date());
    
    };
    
    window.onload = function () {
    
    let loadTime = new Date();
    let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
    let refreshTime = loadTime.getTime() - unloadTime.getTime();
    
    if(refreshTime>3.6e+6)//3000 milliseconds
    {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("auth");
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("password");


    }
    
    };
    const toastValue = useSelector((state)=> state.toastReducers)
    

    
   useEffect(() => {
    toast.success(toastValue)
    const Auth1 = localStorage.getItem("username");
    const Auth2 = localStorage.getItem("password");

    if (Auth1 !== null && Auth2 !== null) {
        return <Redirect to="/" />
    }else{
        return <Redirect to="/dashboard" />
    }
})
const LoadSettingTitle  = useCallback (async() => {
 await axios.get(`${PORT}/setting/getSettingDetails`, { headers: {  authtoken: Token  } })
    .then((res) => {
      let titleTextTop = document.getElementById("title-text")
      titleTextTop.innerHTML = (res.data.data[2].content !== undefined ? res.data.data[2].content : 'N/A')
    })

},[Token, PORT])
useEffect(() => {
  if(Token){
  LoadSettingTitle()
  }
}, [LoadSettingTitle,Token])

  const toggle = useSelector((state)=> state.toggleReducers)


  return (
    <div className={toggle ? "App" : "App toggleClass"}>


    
    <BrowserRouter>
    <Header/>
     <SideNav/>
     <main>
    <Switch>
    
        {/* login routes */}
      <Route exact path="/" component={Login} />
      <Route exact path="/resetPassword/:resetPwdToken" component={ResetPassword} />
      <Route exact path="/resetmail" component={ResetMail} />
      <Route exact path="/resetPasswordLink" component={ResetPasswordLink} />


      {/* dashboard routs */}
      <Protected exact path="/dashboard" component={Dasboard} />

      {/* User Mangement routes */}
      <Protected exact path="/userManagement" component={UserMangement} />
      <Protected exact path="/viewPlant/:id" component={ViewPlant} />
      <Protected exact path="/deviceList/:id" component={UserViewDevice} />

       {/* device Mangement routes */}
       <Protected exact path="/deviceManagement" component={DeviceMangement} />

       {/* content Mangement routes */}
       <Protected exact path="/contentManagement" component={ContentManagement} />
       <Protected exact path="/editContent/:key" component={EditContent} />
       <Route exact path="/cms/:id" component={ViewBlog} />


         {/* category Mangement routes */}
         <Protected exact path="/categoryMangement" component={CategoryMangement} />
         <Protected exact path="/editCategory/:id" component={EditCategory} />
         <Protected exact path="/addCategory" component={AddCategory} />

         
       {/* contact quries routes */}
       <Protected exact path="/contactQuries" component={ContactQuries} />
       {/* <Protected exact path="/contactDetails/:id" component={ContactDetails} /> */}
       <Protected exact path="/contactDetails/:id" component={ContactDetails} />



        {/* Setting management routes */}
        <Protected exact path="/settings" component={Setting} />

          {/* cms pages */}
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
         <Route exact path="/faq" component={FAQ} />
         <Route exact path="/howItWork" component={HOW_IT_WORK} />

     
    </Switch>
    </main>
    </BrowserRouter>
      <ToastContainer/>
    </div>
  );
}

export default App;
