import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import PORTNUMBER from "../../api/api";

import "react-toastify/dist/ReactToastify.css";

import FormValidation from "../../formValidation/formValidation";
import { toast } from "react-toastify";


const EditCategory = () => {
  const { id } = useParams();
  const Token = localStorage.getItem("authtoken");
  const PORT = PORTNUMBER.PORTNUMBER;
  const history = useHistory();
  const [AllCategory, setAllCategory] = useState(["not"]);
  const {
    register,
    handleSubmit,
  } = useForm();

  // const LoadCategory = async () => {
  //     await axios.get(`${PORT}/category/adminList`, { headers: { authtoken: Token } })
  // }
  const loadCategoryId = useCallback( async () => {
    await axios
      .post(
        `${PORT}/category/getDetails`,
        { categoryId: id },
        { headers: { authtoken: Token } }
      )
      .then((res, err) => {
        setAllCategory(res.data.data);
      });
  },[PORT,Token,id]);
  useEffect(() => {
    FormValidation();
    loadCategoryId();
  }, [loadCategoryId]);

  const onSubmit = (e) => {
    var fd = new FormData();

    var inputs = document.getElementsByClassName("form-control");

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === "file") {
        var fileVal = inputs[i].files[0];
        fd.append(inputs[i].name, fileVal);
      } else {
        fd.append(inputs[i].name, inputs[i].value);
      }
    }
    fd.append("categoryId", id);

    axios
      .post(`${PORT}/category/edit`, fd, { headers: { authtoken: Token } })
      .then((res) => {
        // LoadCategory();
        if(res.data.status === 400){
          toast.success("This category already exists.");
        }else{
        toast.success("Category is updated successfully.");
        history.push("/categoryMangement");
        }
      });
  };
  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-sm-6">
              <div className="main-head">
                <h4>Edit Category</h4>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="theme-btn text-right top-head">
                <Link to="/categoryMangement" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="needs-validation"
              noValidate
            >
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="validationCustom01"
                      className="form-control"
                      defaultValue={AllCategory.name}
                      name="name"
                      {...register("name", { required: false })}
                      placeholder="Name"
                    />
                    <div className="invalid-feedback">
                      Please enter category Name.
                    </div>
                  </div>
                </div>
                <div className=" col-sm-6">
                  <div className="form-group file-upload-button">
                    <button className="btn">Upload image </button>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      id="image"
                      {...register("image", { required: false })}
                    />
                    <div className="invalid-feedback">
                    Upload Category Image.
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      type="text"
                      id="validationCustom02"
                      className="form-control"
                      defaultValue={
                        AllCategory && AllCategory !== null
                          ? AllCategory["description"]
                          : "N/A"
                      }
                      name="description"
                      {...register("description", { required: false })}
                      placeholder="description"
                    />
                    <div className="invalid-feedback">
                      Please enter description.
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-right">
                    <button type="submit" className="btn">
                      Save
                    </button>
                    <Link to="/categoryMangement" className="btn">
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditCategory;
