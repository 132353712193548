
import React, { useState, useEffect, useCallback } from 'react';

import axios from 'axios';
import moment from 'moment'
import { GiFlowerPot } from 'react-icons/gi';



import PORTNUMBER from '../../api/api'

import { Link,useParams } from 'react-router-dom'



const ViewPlant = () => {
    const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("authtoken");
    const { id } = useParams()
   
    // modal active
   

    // const [Loading, setLoading] = useState(false);


    const [users, setUser] = useState([]);
    const [loading, setLoading] = useState(false);







    const LoadUser = useCallback( async () => {
        await axios.post(`${PORT}/userPlant/${id}`,{}, { headers: { authtoken: Token } })
            .then((res) => {
                setUser(res.data.data);
            })

        setLoading(true);
    },[PORT,Token,id])



    useEffect(() => {
        LoadUser()
    }, [LoadUser])


    return (
        <div className="tables-field">
            <div className="project-head">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Plant List For User</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="theme-btn top-head text-right">
                                <Link to="/userManagement" className="btn">Back</Link>
                                                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="table-order shadow">
                    <table>
                        <tbody>
                            <tr>
                                <th>S.No</th>
                                <th>Plant Name</th>
                               <th>Plant Type</th>
                                <th>Device Name</th>
                                <th>Device Code</th>
                                <th>Added On</th>
                                <th>Status</th>
                                {/* <th>Action</th> */}
                            </tr>
                        
                            {loading ? users && users.length ? users.map((user,index) => (
                                <tr key={user._id}>
                                     <td >
                                        {index+1}
                                    </td>
                                    <td>{user.name?user.name:'-'}</td>
                                    <td >
                                        {user.categoryName?user.categoryName:'-'}
                                    </td>
                                    <td>{user.deviceName?user.deviceName:'-'}</td>
                                    <td>{user.devicecode?user.devicecode:'-'}</td>
                                    <td>{moment(user.created_at).format("DD/MM/YYYY")}</td>
                                    <td className=""><span className={`badge bg-success ${(user.plantStatus !== "Connected") ? "inactive" : "active"}`}>{(user.isDeleted) ? "Deleted" : user.plantStatus}</span>
                                    </td>
                                    {/* <td className="action-top">
                                     
                                        <Link to="/viewPlant" className="icons-action"><FaEye /></Link>
                                        <Link to="/deviceList" className="icons-action"><BiDevices /></Link>
                                        {
                                            user.status == "inactive" ? <span onClick={() => activeHandleShow(user._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => inActiveHandleShow(user._id)} className="icons-action"><FaBan /></span>

                                        }


                                    </td> */}

                                </tr>

                            )) :<tr ><td className="no-data">NO ANY USER FOUND</td></tr> :  
                            <tr>
                                <td>
                                <div className="loader-wrapper">
                            <div className="loader">
                              <div className="loading-svg">
                                <GiFlowerPot />
                              </div>
                            </div>
                          </div>
                                </td>
                            </tr>
                            }

                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
    )
}
export default ViewPlant;