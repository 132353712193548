import React from "react"
import { Accordion, Card } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import { AiOutlineSetting } from 'react-icons/ai';




const Setting=()=>{
    return(
        <Card>
        <Accordion.Toggle as={Card.Header} >
          <ul>
            <li className="deactive"><NavLink to="/settings" ><span className="image-nav"><AiOutlineSetting/></span><span className="img-active"><AiOutlineSetting/></span>Settings <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
            </li>

          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <ul>
              <li className=""><NavLink to="/settings"><span className="image-nav"><AiOutlineSetting /></span><span className="img-active"><AiOutlineSetting /></span>Settings <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
              </li>
             
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
}

export default Setting;