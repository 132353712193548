import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const Protected =({component:Cmp, ...rest})=>(
    <Route 
    {...rest}
    render={(props)=> 
    localStorage.getItem("authtoken")?(
        <Cmp {...props}/>
    ):<Redirect to="/" />}
    />
)


// const Protected =({isAuth:isAuth, conponent:Component, ...rest})=>(
//     <Route 
//     {...rest}
//     render={(props)=>{
//         if(isAuth){
//             return <Component/>;
//         }else{
//             return(
//                 <Redirect to={{pathname:"/", state:{from: props.location}}}/>
//             )
//         }
//     }
//  }
//     />
// )

export default Protected;