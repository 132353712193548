
import React, { useState,useEffect } from 'react'
import { Link } from "react-router-dom"
import { useForm } from 'react-hook-form';
import PORTNUMBER from '../../api/api'
import axios from 'axios'
import { useHistory } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import {toasterValue} from '../../../redux/actions/action'
import { useDispatch } from 'react-redux';

import FormValidation from '../../formValidation/formValidation'





const AddCategory = () => {
    const dispatch = useDispatch()
    const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("authtoken")
    const history = useHistory()
    const [CatErr, setCatErr] = useState()
    const { register, handleSubmit } = useForm();
    const LoadCategory = async() => {
        await axios.post(`${PORT}/getAllCatergory`,{}, { headers: { authtoken: Token } });
    }

    const onSubmit = async(data) => {

        const image = document.getElementById('validationCustom03').files[0];
        var fd = new FormData();
        fd.append('name', data.name);
        fd.append('description', data.description);
        fd.append('image', image)

        await axios.post(`${PORT}/category/add`, fd,{ headers: { authtoken: Token } })
            .then((res) => {
                if (res.data.success) {
                    dispatch(toasterValue("You have successfully added a new category"));
                     LoadCategory();
                     history.push("/categoryMangement")
                } else {
                    setCatErr(res.data.message)
                }

            })

    };

    useEffect(() => {
        FormValidation()
        return () => {
            
        }
    }, [])

    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Add New Category</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/categoryMangement"  className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className=" col-sm-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustom01" name="name"  {...register('name', { required: true })} placeholder="Name" required/>
                                        <small className=" form-text text-muted">{CatErr}</small>
                                        <div className="invalid-feedback">
                                        Please enter Category Name.
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-sm-6">
                                    <div className="form-group file-upload-button">
                                    <button className="btn">Upload image </button>
                                            <input required type="file" name="image" id="validationCustom03" {...register('image', { required: true })}  className="form-control" />
                                            <small className=" form-text text-muted">{CatErr}</small>
                                        <div className="invalid-feedback">
                                        Upload Category Image.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <textarea type="text" className="form-control" id="validationCustom02" name="description"  {...register('description', { required: true })} placeholder="description" required/>
                                        <div className="invalid-feedback">
                                            Please enter description.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Save</button>
                                        <Link to="/categoryMangement" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddCategory;