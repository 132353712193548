import React, { useState, useEffect, useRef } from "react";


import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import JoditEditor from "jodit-react";

import { Link, useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import PORTNUMBER from "../../api/api";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditContent = () => {
  const editor = useRef(null);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  // const [editor, setEditor] = useState(() => EditorState.createEmpty(),)
  // useEffect(() => {
  //   setEditor(true)
  // })
  // const onEditorStateChange = editorState => {
  //     setEditor( editorState );
  //   };

  const { key } = useParams();
  const PORT = PORTNUMBER.PORTNUMBER;
  const Token = localStorage.getItem("authtoken");
  const history = useHistory();
  const [AllContent, setAllContent] = useState({});
  const [content, setContent] = useState();

  const {
    register,
    handleSubmit,
  } = useForm();

  // const LoadContent = async () => {
  //     await axios.get(`${PORT}/cms/getPageById`, { headers: { authtoken: Token } })
  // }

  useEffect(() => {
    axios
      .post(
        `${PORT}/cms/getPageById`,
        { pageId: key },
        { headers: { authtoken: Token } }
      )
      .then((res) => {
        setAllContent(res.data.data);
        setContent(res.data.data.content);
      });
  }, [PORT,key,Token]);
  
  const onSubmit = async (data) => {
    var fd = new FormData();

    var inputs = document.getElementsByClassName("form-control");

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].type === "file") {
        var fileVal = inputs[i].files[0];
        fd.append(inputs[i].name, fileVal);
      } else {
        fd.append(inputs[i].name, inputs[i].value);
      }
    }
    
    const DataValue = {
      content: content,
      pageName: data.pageName?data.pageName:AllContent.pageName,
      pageHeading:data.pageName?data.pageName:AllContent.pageName,
      pageId: key,
    };
   await axios
      .post(`${PORT}/cms/editPage`, DataValue, {
        headers: { authtoken: Token },
      })
      .then((res) => {
        toast.success("Content is updated successfully.");
       history.push("/contentManagement");
      });
  };

  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="row">
            <div className="col-sm-6">
              <div className="main-head">
                <h4>Edit Content</h4>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="theme-btn text-right top-head">
                <Link to="/contentManagement" className="btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                {/* <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" name="key" defaultValue={AllContent.key}  {...register('key', { required: true })} placeholder="Key" required />
                                    </div>
                                </div> */}
                <div className="col-sm-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      defaultValue={AllContent.pageName}
                      {...register("pageName", { required: false })}
                      placeholder="Title"
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <JoditEditor
                    className="form-control"
                    name="content"
                    {...register("content", { required: false })}
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                  />
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn mt-2 btn-group-right text-right">
                    <button type="submit" className="btn">
                      Submit
                    </button>
                    <button type="button" className="btn">
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditContent;
