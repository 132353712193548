import React,{useEffect,useState,useCallback} from 'react'

import PORTNUMBER from '../../api/api'
import axios from 'axios'

import ReactHtmlParser from 'react-html-parser';

import {Spinner} from 'react-bootstrap'

const PrivacyPolicy =()=>{
    const PORT = PORTNUMBER.PORTNUMBER

    const [content, setContent] = useState([]);
    // const [title, setTitle] = useState([]);

    const [Loading, setLoading] = useState(false);


    const LoadContent = useCallback( async () => {
        await axios.get(`${PORT}/content/details`)
            .then((res) => {
                console.log("res", res)
                setContent(res.data.data[0].content)
                // setTitle(res.data.data[0].title)

            })
            setLoading(true)
    },[PORT])


    useEffect(() => {
        LoadContent()
    }, [LoadContent])


    useEffect(() => {
        document.body.classList.add("remove-header_cms");
        
        return () => {
            document.body.classList.remove("remove-header_cms");

        }
    }, [])

    return(
        <div className="cms-content" >
           
         {
         Loading ? content && content.length ?
         ReactHtmlParser(content):
         <span className="no-data">No Data Found</span>:
         <div className="spinner-top">
         <Spinner animation="grow" variant="danger" /> 
         </div>
         }
         </div>
        

    );
}

export default PrivacyPolicy;