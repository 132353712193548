import React from "react"
import { Accordion, Card } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import {MdDashboard} from 'react-icons/md'


const Dashboard=()=>{
    return(
        <Card>
        <Accordion.Toggle as={Card.Header}>
          <ul>
            <li className="deactive"><NavLink to="/dashboard"><span className="image-nav"><MdDashboard/></span><span className="img-active"><MdDashboard/></span>Dashboard <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
            </li>

          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse >
          <Card.Body>
            {/* <ul>
              <li className=""><a href="#"><span className="img-active"><MdDashboard/></span>Provider <i className="fa fa-angle-right" aria-hidden="true" /></a>
              </li>
             
            </ul> */}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
}

export default Dashboard;