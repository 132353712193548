import { useCallback, useEffect, useState } from 'react';
import { Bar } from "react-chartjs-2";
import axios from 'axios'
import PORTNUMBER from '../api/api'
// import Chart from './costomerChart/costomerChart'
// import EarningGraph from './earningGraph/earningGraph'





const Dashboard = () => {
  const token = localStorage.getItem("authtoken")
  const PORT = PORTNUMBER.PORTNUMBER
  const [totalUsers, setTotalUsers] = useState()
 

  const [customerData, setCustomerData] = useState({});
  const [plantData, setPlantData] = useState({});
  const [deviceData, setDeviceData] = useState({});
  const [contactData, setContactData] = useState({});




  var options ={  
    scales: {
        yAxes: [{
            ticks: {
                precision: 0,
            }
        }]
    }
};

  const customerGraph = useCallback( () => {


    // monthly Customers graph 
    let dataMonth = [];
    let dataValue = [];
    axios.get(`${PORT}/userGraph`,{ headers: { authtoken: `${token}` } })
      .then((res) => {
        if(res.data.data && res.data.data.length){
        for (const dataObj of res.data.data) {
          dataMonth.push(dataObj['month'])
          dataValue.push(parseInt(dataObj['value']))
        }
      }
        setCustomerData({
          labels: dataMonth,
          datasets: [
            {
              label: "Users",
              data: dataValue,
              // fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
              borderWidth: 1,
            }
          ]
        })
      })
  },[PORT,token])

  const DeviceGraph = useCallback(  () => {


    // monthly Device graph 
    let dataMonth = [];
    let dataValue = [];
    axios.get(`${PORT}/deviceGraph`,{ headers: { authtoken: `${token}` } })
      .then((res) =>{
        if(res.data.data && res.data.data.length){
        for (const dataObj of res.data.data) {
          dataMonth.push(dataObj['month'])
          dataValue.push(parseInt(dataObj['value']))
        }
      }
        setDeviceData({
          labels: dataMonth,
          datasets: [
            {
              label: "Devices",
              data: dataValue,
              // fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
              borderWidth: 1,
            }
          ]
        })
      })

  },[PORT,token])


  const plantsGraph = useCallback(  () => {

   // monthly plant graph 
   let dataMonth = [];
   let dataValue = [];
   axios.get(`${PORT}/plantGraph`,{ headers: { authtoken: `${token}` } })
     .then((res) => {
      if(res.data.data && res.data.data.length){
      for (const dataObj of res.data.data) {
        dataMonth.push(dataObj['month'])
        dataValue.push(parseInt(dataObj['value']))
      }
    }
      setPlantData({
          labels: dataMonth,
          datasets: [
            {
              label: "Plants",
              data: dataValue,
              // fill: true,
              backgroundColor: "rgba(75,192,192,0.2)",
              borderColor: "rgba(75,192,192,1)",
              borderWidth: 1,
            }
          ]
        })
      })

  },[PORT,token])

  const contactGraph = useCallback(  () => {

    // monthly contact graph 
    let dataMonth = [];
    let dataValue = [];
    axios.get(`${PORT}/contactGraph`,{ headers: { authtoken: `${token}` } })
      .then((res) => {
        if(res.data.data && res.data.data.length){
       for (const dataObj of res.data.data) {
         dataMonth.push(dataObj['month'])
         dataValue.push(parseInt(dataObj['value']))
       }
      }
       setContactData({
           labels: dataMonth,
           datasets: [
             {
               label: "Contacts",
               data: dataValue,
              //  fill: true,
               backgroundColor: "rgba(75,192,192,0.2)",
               borderColor: "rgba(75,192,192,1)",
               borderWidth: 1,
             }
           ]
         })
       })
 
   },[PORT,token])


  useEffect(() => {
     customerGraph();
     DeviceGraph();
     plantsGraph();
     contactGraph();

    document.body.classList.remove("remove-header");
    // total user 
    axios.post(`${PORT}/dashboard`,{},{ headers: { authtoken: token } })
      .then((res) => {
        setTotalUsers(res.data.data)
      })

  

  }, [customerGraph,DeviceGraph,plantsGraph,contactGraph,PORT,token])


  return (
    <>
      <section className="analyst-sec">
        <div className="container-fluid">
          <div className="col-12">
            <div className="main-head">
              <h4>Dashboard</h4>
            </div>
          </div>
          <div className="col-12 p-0">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="box rounded graph shadow">
                      <div className="box-con">
                        <p>Total Users</p>
                        {/* <h3>{wine.category && wine.category !== null ? wine.category['name']:'N/A'}</h3> */}
                        <h3>{totalUsers && totalUsers !== null ? totalUsers['userCount'] : 'N/A'}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="box graph rounded shadow">
                      <div className="box-con green">
                        <p>Total Devices</p>
                        <h3>{totalUsers && totalUsers !== null ? totalUsers['deviceCount'] : 'N/A'}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="box graph rounded shadow">
                      <div className="box-con green">
                        <p>Total Plants</p>
                        <h3>{totalUsers && totalUsers !== null ? totalUsers['plantCount'] : 'N/A'}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-4">
                <div className="tab-head box rounded shadow">
                  <div className="graph ">
                    <div className="box-con mb-2 p-0">
                      <p>Month /Customer Graph</p>
                    </div>
                    <Bar data={customerData}   options = {options}/>
                    {/* <Chart/> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tab-head box rounded shadow">
                  <div className="graph ">
                    <div className="box-con mb-2 p-0">
                      <p>Month/ Devices Graph</p>
                    </div>
                    <Bar data={deviceData} options = {options}/>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="tab-head box rounded shadow">
                  <div className="graph ">
                    <div className="box-con mb-2 p-0">
                      <p>Month/ Plants Graph</p>
                    </div>
                    <Bar data={plantData} options = {options} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
             
                  <div className="col-md-4 col-sm-6">
                    <div className="box rounded graph shadow">
                      <div className="box-con">
                        <p>Total Queries</p>
                        <h3>{totalUsers && totalUsers !== null ? totalUsers['contactCount'] : 'N/A'}</h3>
                      </div>
                    </div>
                  </div>
                 </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                <div className="col-md-4">
                <div className="tab-head box rounded shadow">
                  <div className="graph ">
                    <div className="box-con mb-2 p-0">
                      <p>Month/ total Query</p>
                    </div>
                    <Bar data={contactData} options = {options}/>
                  </div>
                </div>
              </div>
                </div>
            </div>

        </div>
      </section>

    </>
  )
}
export default Dashboard;