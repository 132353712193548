const PORTNUMBER = "https://plant.betademo.net/backend/v1/admin";
//const PORTNUMBER = "http://localhost:3700/v1/admin";
// const PORTNUMBER = "http://10.0.0.5:3002/admin";
const PORTNUMBER1 = "https://plant.betademo.net/backend/v1/user/admin";


const API = {
  PORTNUMBER:`${PORTNUMBER}`,

  // LOGIN_API:`${PORTNUMBER}/adminLogin`,
  LOGIN_API:`${PORTNUMBER}/login`,

  // FORGETPASSWORD_API:`${PORTNUMBER}/forgetPassword`,
  FORGETPASSWORD_API:`${PORTNUMBER}/verifyUser`,
  SENDFORGOTPASSMAIL_API:`${PORTNUMBER1}/forgetPassword`,
  RESETPASSWORD_API:`${PORTNUMBER1}/resetPassword`,


  CHANGEPASSWORD_API:`${PORTNUMBER}/changePassword

  `
}
export default API;