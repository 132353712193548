import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { ImageObj } from "../../assets/images/imgeObject";
import { Dropdown, Modal, Button } from "react-bootstrap";
import CHANGEPASSWORD_API from "../../views/api/api";
import {
  addToggle,
  removeToggle,
  toasterValue,
} from "../../redux/actions/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const Token = localStorage.getItem("authtoken");
  const PORT = CHANGEPASSWORD_API.PORTNUMBER;
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [Logo, setLogo] = useState();



  const [visibilityConfirm, setVisibilityConfirm] = useState(true);
  const [visibilityNew, setVisibilityNew] = useState(true);

  const history = useHistory();

  const handleClose = () => setShow(false);
  const handleClosePw = () =>{
    setShowPassword(false)
    setVisibilityNew(true)
    setVisibilityConfirm(true)
    reset()
} 
  const handleShowPw = () => setShowPassword(true);

  const toggle = useSelector((state) => state.toggleReducers);


  const pwVisibilityConfirm = (value) => {
    setVisibilityConfirm(value);
  };

  const pwVisibilityNew = (value) => {
    setVisibilityNew(value);
  };

  const LogOut = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("authtoken");
    dispatch(toasterValue("You have logged out successfully. "));
    history.push("/");

    //  window.location.reload()
  };

  const LoadUser = useCallback( async () => {
    await axios
      .get(`${PORT}/setting/getSettingDetails`, {
        headers: { authtoken: Token },
      })
      .then((res) => {
        if (res.data.data && res.data.data.length) {
          setLogo(res.data.data[3].content);
        }else{
            setLogo('');
        }
      });
  },[PORT,Token]);

  useEffect(() => {
    LoadUser();
  }, [LoadUser]);

  // form validation rules
  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const changePassword = (data) => {
    axios
      .post(
        CHANGEPASSWORD_API.CHANGEPASSWORD_API,
        { password: data.password, newPassword: data.newPassword },
        { headers: { authtoken: Token } }
      )
      .then((res) => {
        if (res.data.status !== 200) {
          handleClosePw();
          // LogOut()
          toast.success("Your password is changed successfully");
          reset({ password: "", newPassword: "", confirm_password: "" });
        } else {

        }
      });

    return false;
  };

  return (
    <header className="top-header">
      <div className="container-fluid">
        <div className="col-md-12 p-0">
          <div className="row">
            <div className="col-sm-6">
              <div className="top-search d-flex main-slide main-tog">
                <div className="logo-image">
                  {Logo && Logo !== undefined ? (
                    <div className="image-in">
                    <img src={Logo} alt="Plant App"></img>
                    </div>
                  ) : (
                    <h2>Plant App</h2>
                  )}
                </div>
                {toggle ? (
                  <div
                    className="toggle-top"
                    onClick={() => dispatch(addToggle(false))}
                  >
                    <div id="toggle">
                      <div className="one" />
                      <div className="two" />
                      <div className="three" />
                    </div>
                  </div>
                ) : (
                  <div
                    className="toggle-top"
                    onClick={() => dispatch(removeToggle(true))}
                  >
                    <div id="toggle" className="on">
                      <div className="one" />
                      <div className="two" />
                      <div className="three" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="d-flex user-top">
                <p className="hover-white">Welcome Admin</p>

                <Dropdown className="theme-btn">
                  <Dropdown.Toggle
                    variant="success"
                    className="btn"
                    id="dropdown-basic"
                  >
                    <img
                      className="dashboard-icon"
                      src={ImageObj.MaleDoc}
                      alt="side-img"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item  onClick={handleShow}>Update Profile</Dropdown.Item> */}
                    <Dropdown.Item onClick={handleShowPw}>
                      Change Password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={LogOut}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        {/* modal edit */}
        <div className="modal-edit">
          <Modal show={show} onHide={handleClose} className="edit-content">
            <div className="max-new-second">
              <div className="main-theme bg-theme border shadow">
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <section className="select-state">
                  <div className="state-bor">
                    <div className="log-in otp-num">
                      <div className="select-steps-box">
                        <div className="input-head">
                          <h2>Update Profile</h2>
                        </div>
                      </div>
                      <div className="select-box-top pr-0">
                        <div className="input-box">
                          <form>
                            <div className="col-12 p-0">
                              <div className="row">
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                     
                                      aria-describedby="emailHelp"
                                      placeholder="Name"
                                      required
                                    />
                                    <small
                                      id="emailHelp"
                                      className=" d-none form-text text-muted"
                                    >
                                      We'll never share your email with anyone
                                      else.
                                    </small>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                     
                                      aria-describedby="emailHelp"
                                      placeholder="Email"
                                      required
                                    />
                                    <small
                                      id="emailHelp"
                                      className="d-none form-text text-muted"
                                    >
                                      We'll never share your email with anyone
                                      else.
                                    </small>
                                  </div>
                                </div>
                                <div className="col-sm-12">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                     
                                      aria-describedby="emailHelp"
                                      placeholder="Mobile"
                                      required
                                    />
                                    <small
                                      id="emailHelp"
                                      className="d-none form-text text-muted"
                                    >
                                      We'll never share your email with anyone
                                      else.
                                    </small>
                                  </div>
                                </div>

                                <Modal.Footer>
                                  <div className="theme-btn btn-group-right">
                                    <Button
                                      className="btn"
                                      variant="secondary"
                                      onClick={handleClose}
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      className="btn"
                                      variant="primary"
                                      onClick={handleClose}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </Modal.Footer>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Modal>

          {/* modal edit */}
          <div className="modal-edit">
            <Modal
              show={showPassword}
              onHide={handleClosePw}
              className="edit-content"
            >
              <div className="max-new-second">
                <div className="main-theme bg-theme border shadow">
                  <Modal.Header closeButton className="pr-0 pl-0">
                    <Modal.Title>
                      {" "}
                      <div className="input-head">
                        <h2>Change Password</h2>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <section className="select-state">
                    <div className="state-bor">
                      <div className="log-in otp-num">
                        <div className="select-box-top pr-0">
                          <div className="input-box">
                            <form onSubmit={handleSubmit(changePassword)}>
                              <div className="col-12 p-0">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <div className="form-group icon-position">
                                      {/* <input
                                        type={visibility ? "password" : "text"}
                                        className="form-control"
                                        {...register("password", {
                                          required: true,
                                        })}
                                        placeholder="Current Password"
                                        required
                                      />
                                      {visibility ? (
                                        <span
                                          className="icon"
                                          onClick={() => pwVisibility(false)}
                                        >
                                          <FaEye />
                                        </span>
                                      ) : (
                                        <span
                                          className="icon"
                                          onClick={() => pwVisibility(true)}
                                        >
                                          <FaEyeSlash />
                                        </span>
                                      )} */}
                                      {/* <small
                                        id="emailHelp"
                                        className=" form-text text-muted"
                                      >
                                        {currentpPassErr}
                                      </small> */}
                                    </div>
                                  </div>
                                  <div className="col-sm-12">
                                    <div className="form-group icon-position">
                                      <input
                                        type={
                                          visibilityNew ? "password" : "text"
                                        }
                                        className={`form-control ${
                                          errors.newPassword ? "is-invalid" : ""
                                        }`}
                                        name="newPassword"
                                        {...register("newPassword", {
                                          required: true,
                                        })}
                                        placeholder="New Password"
                                        required
                                      />
                                      {visibilityNew ? (
                                        <span
                                          className="icon"
                                          onClick={() => pwVisibilityNew(false)}
                                        >
                                          <FaEye />
                                        </span>
                                      ) : (
                                        <span
                                          className="icon"
                                          onClick={() => pwVisibilityNew(true)}
                                        >
                                          <FaEyeSlash />
                                        </span>
                                      )}
                                      <div className="invalid-feedback">
                                        {errors.newPassword?.message}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-12">
                                    <div className="form-group icon-position">
                                      <input
                                        type={
                                          visibilityConfirm
                                            ? "password"
                                            : "text"
                                        }
                                        className={`form-control ${
                                          errors.confirm_password
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        name="confirm_password"
                                        {...register("confirm_password", {
                                          required: true,
                                        })}
                                        placeholder="Confirm Password"
                                        required
                                      />
                                      {visibilityConfirm ? (
                                        <span
                                          className="icon"
                                          onClick={() =>
                                            pwVisibilityConfirm(false)
                                          }
                                        >
                                          <FaEye />
                                        </span>
                                      ) : (
                                        <span
                                          className="icon"
                                          onClick={() =>
                                            pwVisibilityConfirm(true)
                                          }
                                        >
                                          <FaEyeSlash />
                                        </span>
                                      )}
                                      <div className="invalid-feedback">
                                        {errors.confirm_password?.message}
                                      </div>
                                    </div>
                                  </div>

                                  <Modal.Footer>
                                    <div className="theme-btn btn-group-right">
                                      <Button
                                        type="submit"
                                        className="btn"
                                        variant="secondary"
                                      >
                                        Update
                                      </Button>
                                      <Button
                                        className="btn"
                                        variant="primary"
                                        onClick={handleClosePw}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </Modal.Footer>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
