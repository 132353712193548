import { FaEdit, FaBan } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios';
import PORTNUMBER from '../../api/api'
import { ToastContainer, toast, } from "react-toastify";
import { GiFlowerPot } from 'react-icons/gi';

// Import Datepicker


import { Modal, Button } from 'react-bootstrap'





const CategoryList = () => {
    const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("authtoken")

   
    const [AllCategory, setAllCategory] = useState([]);

    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [inActiveShow, setInActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    const [loading, setLoading] = useState(false);

    const [Count, setCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryPerPage] = useState(50);





    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const inActiveHandleClose = () => setInActiveShow(false);
    const inActiveHandleShow = (id) => {
        setInActiveShow(true);
        setStatusId(id)

    }

    const activeStatus = async () => {
        await axios.post(`${PORT}/category/statusChange`, { categoryId: statusId, status: "active" }, { headers: { authtoken: Token } }).then((res, err) => {
            LoadAllCategory()
            toast.success("Category is activated successfully.")
            activeHandleClose()
        })
    }
    const inActiveStatus = async () => {
        await axios.post(`${PORT}/category/statusChange`, { categoryId: statusId, status: "inactive" }, { headers: { authtoken: Token } }).then((res, err) => {
            LoadAllCategory()
            toast.success("Category is inactivated successfully.")
            inActiveHandleClose()
        })
    }

  

    const LoadAllCategory = useCallback( async (pageNumber) => {
        await axios.post(`${PORT}/getAllCatergory`, { currentPage: pageNumber }, { headers: { authtoken: Token } })
            .then((res) => {
                if (res.data.data) {
                    setCount(res.data.data.totalPage)
                    setAllCategory(res.data.data.results)
                }
            })
        setLoading(true)
    },[PORT,Token])

    useEffect(() => {
        LoadAllCategory()

    }, [LoadAllCategory])

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(Count / categoryPerPage); i++) {
        pageNumbers.push(i);
    }

    // Change page


    const paginate = async (pageNumber) => {
        setCurrentPage(pageNumber);
        LoadAllCategory(pageNumber)

    }


    return (
        <div className="tables-field">

            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Category List</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="theme-btn top-head text-right">
                                <Link to="/addCategory" className="btn">Add New Category</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div> */}
                <div className="table-order shadow">
                    <table>
                        <tbody>
                            <tr>
                                <th>S.No</th>
                                <th>image</th>
                                <th>Category Name</th>
                                {/* <th>Description</th> */}
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            {
                                 AllCategory && AllCategory.length ? AllCategory.map((category, index) => (
                                    <tr key={category._id}>
                                        <td className="min-width-td">{(50 * ((currentPage === undefined || currentPage === 0) ? 1 - 1 : currentPage - 1)) + (index + 1)}</td>
                                        <td><img src={category.image} alt="cat" style={{ "width": "40px", "height": "40px" }} /></td>
                                        <td>{category.name}</td>
                                        {/* <td>{category.description}</td> */}
                                        <td>
                                            <span className={`badge bg-success ${category.status}`}> {category.status}</span>
                                        </td>
                                        <td className="action-top">
                                            <Link to={`/editCategory/${category._id}`} className="icons-action"><FaEdit /></Link>
                                            {
                                                category.status === "inactive" ? <span onClick={() => activeHandleShow(category._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => inActiveHandleShow(category._id)} className="icons-action"><FaBan /></span>
                                            }
                                        </td>

                                    </tr>
                                )) :
                                <tr ><td className="no-data">NO CATEGORY FOUND</td></tr> 
                            }


                        </tbody>
                    </table>
                    {
                        !loading ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiFlowerPot />
                                    </div>
                                </div>
                            </div> : ""
                    }
                </div>
                <nav>
                    <ul className='pagination'>
                        {pageNumbers.map(number => (
                            <li key={number} className='page-item'>
                                <p onClick={() => paginate(number)
                                } className='page-link'>
                                    {number}
                                </p>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false}   show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to activate category?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={activeStatus}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}

            {/* inactive modal */}
            <Modal className="status-modal" animation={false}  show={inActiveShow} onHide={inActiveHandleClose}>
                <Modal.Body>Are you sure you want to inactivate category?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={inActiveStatus}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={inActiveHandleClose}>
                            No
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* inactive modal end */}
            <ToastContainer />
        </div>
    )
}
export default CategoryList;