
import React, { useState, useEffect, useCallback } from 'react';
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import axios from 'axios';
import { GiFlowerPot } from 'react-icons/gi';
import PORTNUMBER from '../../api/api'


const ContentList = () => {
    const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("authtoken")

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [content, setContent] = useState([]);
    const [Loading, setLoading] = useState(false);


    const LoadContent = useCallback(async () => {
        await axios.get(`${PORT}/cms/getAllPages`, { headers: { authtoken: Token } })
            .then((res) => {
                setContent(res.data.data.results)
            })
        setLoading(true)
    }, [PORT, Token])

    useEffect(() => {
        LoadContent()
    }, [LoadContent])

    return (
        <div className="tables-field">
            <div className="main-head">
                <h4>Content Pages List</h4>
            </div>
            <div className="project-table">

                <div className="table-order shadow">
                    <table>
                        <tbody>
                            <tr>
                                <th>S.No</th>
                                <th>Title</th>
                                <th>Action</th>
                            </tr>

                            {Loading ? content && content.length ? content.map((contentData, index) => (
                                <tr key={contentData._id}>
                                    <td>{index + 1}</td>
                                    <td>{contentData.pageHeading}</td>
                                    <td className="action-top">
                                        <Link to={`/editContent/${contentData._id}`} className="icons-action"><FaEdit /></Link>

                                    </td>

                                </tr>
                            )) : <tr ><td className="no-data">NO CONTENT FOUND</td></tr> :
                                <tr>
                                    <td>
                                        <div className="loader-wrapper">
                                            <div className="loader">
                                                <div className="loading-svg">
                                                    <GiFlowerPot />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            }

                        </tbody>
                    </table>
                </div>
            </div>

            {/* modal edit */}
            <div className="modal-edit">
                <Modal show={show} onHide={handleClose} className="edit-content">

                    <div className="max-new-second">
                        <div className="main-theme bg-theme border shadow">
                            <Modal.Header closeButton>
                                <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <section className="select-state">
                                <div className="state-bor">
                                    <div className="log-in otp-num">
                                        <div className="select-steps-box">
                                            <div className="input-head">
                                                <h2>Edit List</h2>
                                            </div>
                                        </div>
                                        <div className="select-box-top pr-0">
                                            <div className="input-box">
                                                <form>
                                                    <div className="col-12 p-0">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <input type="email" className="form-control"  aria-describedby="emailHelp" placeholder="Key" />
                                                                    <small id="emailHelp" className=" d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="form-group">
                                                                    <input type="email" className="form-control"  aria-describedby="emailHelp" placeholder="Title" />
                                                                    <small id="emailHelp" className="d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <textarea type="email" className="form-control"  aria-describedby="emailHelp" placeholder="Content" />
                                                                    <small id="emailHelp" className="d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                </div>
                                                            </div>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" onClick={handleClose}>
                                                                    Save Changes
                                                                </Button>
                                                            </Modal.Footer>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                </Modal>
            </div>
        </div>
    )
}
export default ContentList;