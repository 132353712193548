import React, { useState, useEffect, useCallback } from "react";
import { GiFlowerPot } from 'react-icons/gi';

import axios from "axios";
import moment from "moment";


// icons
import { FaSearch } from "react-icons/fa";
import { FaEye, FaBan } from "react-icons/fa";
import { BiDevices } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { toast } from "react-toastify";

import PORTNUMBER from "../../api/api";

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

// react bootstrap
import { Modal, Button } from "react-bootstrap";

const UserManagement = () => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const Token = localStorage.getItem("authtoken");

  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [inActiveShow, setInActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();
  // const [Loading, setLoading] = useState(false);

  const [users, setUser] = useState([]);
  const [Count, setCount] = useState();
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState();
  const [userPerPage] = useState(50);
  const [pageNo, setpageNo] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterForm, setFilterForm] = useState({
    name: "",
    email: "",
    mobile: "",
    status: "",
    startDate: "",
    endDate: "",
  });

  const activeHandleClose = () => setActiveShow(false);
  const activeHandleShow = (id) => {
    setActiveShow(true);
    setStatusId(id);
  };

  const inActiveHandleClose = () => {
    setInActiveShow(false);
  };
  const inActiveHandleShow = (id) => {
    setInActiveShow(true);
    setStatusId(id);
  };

  const activeUser = async () => {
    await axios
      .post(
        `${PORT}/statusUser/${statusId}`,
        { userStatus: 1 },
        { headers: { authtoken: Token } }
      )
      .then((res, err) => {
        LoadUser();
        toast.success("User is activated successfully.");
        activeHandleClose();
      });
  };
  const inActiveUser = async () => {
    await axios
      .post(
        `${PORT}/statusUser/${statusId}`,
        { userStatus: 0 },
        { headers: { authtoken: Token } }
      )
      .then((res, err) => {
        LoadUser();
        toast.success("User is inactivated successfully.");
        inActiveHandleClose();
      });
  };
  const LoadUser = useCallback( async (pageNumber) => {
    await axios
      .post(
        `${PORT}/getAllAdminUser`,
        { currentPage: pageNumber },
        { headers: { authtoken: Token } }
      )
      .then((res) => {
        setUser(res.data.data.results);
        setCount(res.data.data.result.totalUser);
      });
    setLoading(true);
  },[PORT,Token]);

  const handleFilter = async (filterForm) => {
    filterForm['currentPage'] = pageNo;
    await axios
      .post(
        `${PORT}/user/filterlist`,
        filterForm,
        { headers: { authtoken: Token } }
      )
      .then((res, err) => {
        setUser(res.data.data);
        setCount(res.data.result.totalUser);
    setLoading(true)

      });
  };

  const resetLeadFilterForm = () => {
    LoadUser();
    // setRowData(userData);
    // setRowsPerPage(10);
  };
  const handleReset = (e) => {
    setLoading(false)
    e.preventDefault();
    LoadUser();
    const data = {
      name: "",
      email: "",
      mobile: "",
      status: "",
      startDate: "",
      endDate: "",
    };

    setFilterForm(data);
    resetLeadFilterForm(data);
  };

  const handleChanges = (key, value) => {
    const data = { ...filterForm, [key]: value };
    setFilterForm(data);
    // handleFilter(data);

  };

  const handleDataSearch = (e) => {
    setLoading(false)
    e.preventDefault();
    const data = { ...filterForm };
    handleFilter(data);
  };

  useEffect(() => {
    LoadUser();
  }, [LoadUser]);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(Count / userPerPage); i++) {
    pageNumbers.push(i);
  }

  // Change page

  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    LoadUser(pageNumber);
    setpageNo(pageNumber);
    setLoading(false)

  };

  return (
    <div className="tables-field">
      <div className="project-table">
        <div className="col-12">
          <div className="main-head">
            <h4>User List</h4>
          </div>
        </div>
        <div className="table-order-box shadow">
          <div className="tab-head">
            <form>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <input
                      type="text"
                      value={filterForm.name}
                      onChange={(e) => handleChanges("name", e.target.value)}
                      className="form-control"
                      
                      aria-describedby="emailHelp"
                      placeholder="Name"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <input
                      type="email"
                      value={filterForm.email}
                      onChange={(e) => handleChanges("email", e.target.value)}
                      className="form-control"
                      
                      aria-describedby="emailHelp"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <input
                      type="number"
                      value={filterForm.mobile}
                      onChange={(e) => handleChanges("mobile", e.target.value)}
                      className="form-control"
                      
                      aria-describedby="emailHelp"
                      placeholder="Mobile"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      value={filterForm.status}
                      onChange={(e) => handleChanges("status", e.target.value)}
                      aria-label="Default select example"
                    >
                      <option defaultValue>All Status</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group before-con">
                    <DatePicker
                      selectsStart
                      placeholderText="Click to select a date"
                      className="form-control"
                      selected={Date.parse(filterForm.startDate)}
                      maxDate={new Date()}
                      dateFormat="d MMMM, yyyy"
                      onSelect={(e) =>
                        handleChanges(
                          "startDate",
                          moment(e).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group before-con after">
                    <DatePicker
                      selectsEnd
                      placeholderText="Click to select a date"
                      className="form-control"
                      selected={Date.parse(filterForm.endDate)}
                      maxDate={new Date()}
                      dateFormat="d MMMM, yyyy"
                      onSelect={(e) =>
                        handleChanges(
                          "endDate",
                          moment(e).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="theme-btn btn-group-right text-right">
                    <button
                      className="btn"
                      onClick={(e) => handleDataSearch(e)}
                    >
                      Search
                    </button>
                    <button className="btn" onClick={(e) => handleReset(e)}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="project-table">
        <div className="form-search">
          <form>
            <div className="form-group icon-input top-search">
              <input
                type="email"
                className="form-control"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                placeholder="Search"
              />
              <FaSearch />
            </div>
          </form>
        </div>
        <div className="table-order shadow">
          <table>
            <tbody>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Registered On</th>
                <th>Status</th>
                <th>Actions </th>
              </tr>

              { (
                users && users.length ? (
                  users
                    .filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.fullName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        val.email
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      ) {
                        return val;
                      } else if (
                        moment(val.created_at).format("DD/MM/YYYY").includes(searchTerm)
                      ) {
                        return val;
                      } else if (
                        String(val.mobile)
                          .includes(searchTerm)
                      ) {
                        return val;
                      }else{
                        return false
                      }

                    })
                    .map((user, index) => (
                      <tr key={user._id}>
                        <td>{(50 * ((currentPage === undefined || currentPage === 0) ? 1 - 1 : currentPage - 1)) + (index + 1)}</td>
                        <td>{user.fullName}</td>
                        <td>{user.email}</td>
                        <td>{user.mobile}</td>
                        <td>{moment(user.created_at).format("DD/MM/YYYY")}</td>
                        <td className="">
                          <span
                            className={`badge bg-success ${user.userStatus === 0 ? "inactive" : "active"
                              }`}
                          >
                            {user.userStatus === 0 ? "Inactive" : "Active"}
                          </span>
                        </td>
                        <td className="action-top">
                          <Link
                            to={`/viewPlant/${user._id}`}
                            className="icons-action"
                          >
                            <FaEye />
                          </Link>
                          <Link
                            to={`/deviceList/${user._id}`}
                            className="icons-action"
                          >
                            <BiDevices />
                          </Link>
                          {user.userStatus === 0 ? (
                            <span
                              onClick={() => activeHandleShow(user._id)}
                              className="icons-action"
                            >
                              <AiOutlineCheckCircle />
                            </span>
                          ) : (
                            <span
                              onClick={() => inActiveHandleShow(user._id)}
                              className="icons-action"
                            >
                              <FaBan />
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr ><td className="no-data">NO ANY USER FOUND</td></tr>
                )
              ) }
            </tbody>
          </table>
          {
            !loading ?
              <div className="loader-wrapper">
                <div className="loader">
                  <div className="loading-svg">
                    <GiFlowerPot />
                  </div>
                </div>
              </div> : ""
          }
        </div>

        <nav>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <p onClick={() => paginate(number)} className="page-link">
                  {number}
                </p>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {/* modals */}

      {/* active modal */}
      <Modal
        className="status-modal"
        show={activeShow}
        onHide={activeHandleClose}
      >
        <Modal.Body>Are you sure you want to activate user?</Modal.Body>
        <Modal.Footer>
          <div className="theme-btn btn-group-right text-right">
            <Button variant="secondary" onClick={activeUser}>
              Yes
            </Button>
            <Button variant="primary" onClick={activeHandleClose}>
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* active modal end */}

      {/* inactive modal */}
      <Modal
        className="status-modal"
        show={inActiveShow}
        onHide={inActiveHandleClose}
      >
        <Modal.Body>Are you sure you want to inactivate user?</Modal.Body>
        <Modal.Footer>
          <div className="theme-btn btn-group-right text-right">
            <Button variant="secondary" onClick={inActiveUser}>
              Yes
            </Button>
            <Button variant="primary" onClick={inActiveHandleClose}>
              No
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* inactive modal end */}
    </div>
  );
};
export default UserManagement;
