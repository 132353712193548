import React from 'react'
import { Accordion } from 'react-bootstrap'
import  Dashboard from './manu/dashboard/dashboard'
import  UserManagement from './manu/userManageManu/userManageManu'
import  DeviceManagement from './manu/deviceManageManu/deviceManageManu'
import  ContentManagement from './manu/contentManageManu/contentManageManu'
import  ContactManageManu from './manu/contactManageManu/contactManageManu'
import  Setting from './manu/settingManu/settingManu'
import  CategoryManagement from './manu/categoryManageManu/categoryManageManu'





const SideNav = () => {
  return (
      <aside className="property side-nav">
  
        <div className="side-nav-menu">
          <div className="sec">
            <Accordion defaultActiveKey="0">
             <Dashboard/>
             <UserManagement/>
             <CategoryManagement/>
             <DeviceManagement/>
             <ContentManagement/>
             <ContactManageManu/>
             <Setting/>
            </Accordion>
          </div>
        </div>
      </aside>
  )
}

export default SideNav;