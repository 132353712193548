
import React, { useState, useEffect, useCallback } from 'react';

import axios from 'axios';
import moment from 'moment'

import { GiFlowerPot } from 'react-icons/gi';
import PORTNUMBER from '../../api/api'
import { Link,useParams } from 'react-router-dom'

const ViewDevice = () => {
    const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("authtoken");
    const { id } = useParams()
  
    // modal active


    // const [Loading, setLoading] = useState(false);


    const [users, setUser] = useState([]);

    const [loading, setLoading] = useState(false);




    const LoadUser = useCallback( async () => {
        await axios.post(`${PORT}/userDevice/${id}`,{}, { headers: { authtoken: Token } })
            .then((res) => {
                setUser(res.data.data);

            })

        setLoading(true);
    },[PORT,Token,id])



    useEffect(() => {
        LoadUser()
    }, [LoadUser])


  

    // Change page


    
    return (
        <div className="tables-field">
             <div className="project-head">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Device List For User</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="theme-btn top-head text-right">
                                <Link to="/userManagement" className="btn">Back</Link>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="table-order shadow">
                    <table>
                        <tbody>
                            <tr>
                                <th>S.No</th>
                                <th>Device Name</th>
                                <th>Device Code</th>
                                <th>Plant Name</th>
                                <th>Added On</th>
                                <th>Status</th>
                            </tr>
                            {loading ? users && users.length ? users.map((user,index)=> (
                                <tr key={user._id}>
                                     <td >
                                        {index+1}
                                    </td>
                                    <td>{user.name?user.name:'-'}</td>
                                    <td>{user.code?user.code:'-'}</td>
                                    <td >
                                        {user.plantName?user.plantName:'-'}
                                    </td>
                                    <td>{moment(user.created_at).format("DD/MM/YYYY")}</td>
                                    <td className=""><span className={`badge bg-success ${user.deviceStatus !== "Connected" ?"inactive" : "active"}`}>{user.deviceStatus}</span>
                                    </td>
                                   

                                </tr>

                            )) : <tr ><td className="no-data">NO DEVICE FOUND</td></tr> : 
                            <tr>
                                <td>
                                <div className="loader-wrapper">
                            <div className="loader">
                              <div className="loading-svg">
                                <GiFlowerPot />
                              </div>
                            </div>
                          </div>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default ViewDevice;