 import User from './user.svg';
 import MaleDoc from './male-doc.svg';
 import ArrowWhite from './arrow-white.svg';









 export const ImageObj ={
    User:User,
    MaleDoc:MaleDoc,
    ArrowWhite:ArrowWhite

 }

