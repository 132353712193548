import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const ViewBlog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const thePath = window.location.href;
    const lastItem = thePath.substring(thePath.lastIndexOf('/') + 1);
    axios
      .post(`https://plant.betademo.net/backend/v1/admin/cms/getPageId`, { pageId: lastItem })
      .then((response) => {
        setPosts(response.data.data);
      })

    // const posts = blogPost.data;
    // setPosts(posts);
  }, []);

  useEffect(() => {
    document.body.classList.add('remove-header_cms');

    return () => {
      document.body.classList.remove('remove-header_cms');
    };
  }, []);

  return (

    <div className="cms-content" >

      <h3>{posts.pageHeading}</h3>
      {ReactHtmlParser(posts.content)}

    </div>

  );
};
export default ViewBlog;
