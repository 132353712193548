import React from "react"
import { Accordion, Card } from 'react-bootstrap'
import { ImageObj } from '../../../../assets/images/imgeObject'
import {NavLink} from 'react-router-dom'
import {BiBookContent} from 'react-icons/bi'



const ContentManageManu=()=>{
    return(
        <Card>
        <Accordion.Toggle as={Card.Header} >
          <ul>
            <li className="deactive"><NavLink to="/contentManagement" ><span className="image-nav"><BiBookContent/></span><span className="img-active"><BiBookContent/></span>Content Management <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
            </li>

          </ul>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <ul>
              <li className=""><NavLink to="/userManagement"><span className="image-nav"><img className="dashboard-icon" src={ImageObj.MaleDoc} alt="side-img" /></span><span className="img-active"><img className="dashboard-icon" src={ImageObj.MaleDoc} alt="side-img" /></span>UserManagement <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
              </li>
             
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
}

export default ContentManageManu;